.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@import url("https://fonts.googleapis.com/css?family=Roboto");

body {
  font-family: "Roboto", sans-serif;
}
* {
  margin: 0;
  padding: 0;
}
i {
  margin-right: 10px;
}
/*----------bootstrap-navbar-css------------*/
.navbar-logo {
  /* padding: 1px; */
  color: #fff;
}
.navbar-mainbg {
  background-color: #424242;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/pat-back.svg");
  background-position: center;
  background-repeat: repeat;
  padding: 0px;
}
#navbarSupportedContent {
  overflow: hidden;
  position: relative;
}
#navbarSupportedContent ul {
  padding: 0px;
  margin: 0px;
  margin-right: 20px;
}
#navbarSupportedContent ul li a i {
  margin-right: 10px;
}
#navbarSupportedContent li {
  list-style-type: none;
  float: left;
}
#navbarSupportedContent ul li a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  font-size: 15px;
  display: block;
  padding: 20px 20px;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  position: relative;
}
#navbarSupportedContent > ul > li.active > a {
  color: #ffffff;
  background-color: transparent;
  transition: all 0.7s;
}
#navbarSupportedContent a:not(:only-child):after {
  content: "\f105";
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 14px;
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  transition: 0.5s;
}
#navbarSupportedContent .active > a:not(:only-child):after {
  transform: rotate(90deg);
}
/* .hori-selector-container {
  position: relative;
} */
.hori-selector {
  display: inline-block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-top: 10px;
}
.hori-selector::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #1f2029;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/pat-back.svg");
  background-position: center;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  -webkit-transition: background 300ms linear;
  transition: background 300ms linear;
}
body.light .hori-selector::before {
  background-color: #ffffff;
}
.hori-selector .right,
.hori-selector .left {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #1f2029;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/pat-back.svg");
  background-position: center;
  -webkit-transition: background 300ms linear;
  transition: background 300ms linear;
  bottom: 10px;
}
.hori-selector .right {
  right: -25px;
}
.hori-selector .left {
  left: -25px;
}
.hori-selector .right:before,
.hori-selector .left:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/pat-back.svg");
  background-position: center;
  background-color: #424242;
}
.hori-selector .right:before {
  bottom: 0;
  right: -25px;
}
.hori-selector .left:before {
  bottom: 0;
  left: -25px;
}

@media (min-width: 790px) {
  .navbar-expand-custom {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-custom .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-custom .nav-but-wrap {
    display: none;
  }
  .navbar-expand-custom .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    flex-direction: row-reverse;
  }
}

@media (max-width: 790px) {
  #navbarSupportedContent ul li a {
    padding: 12px 30px;
  }
  .hori-selector {
    margin-top: 0px;
    margin-left: 10px;
    border-radius: 0;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .hori-selector .left,
  .hori-selector .right {
    right: 10px;
  }
  .hori-selector .left {
    top: -25px;
    left: auto;
  }
  .hori-selector .right {
    bottom: -25px;
  }
  .hori-selector .left:before {
    left: -25px;
    top: -25px;
  }
  .hori-selector .right:before {
    bottom: -25px;
    left: -25px;
  }
}

/* .mylogo:hover{
	background-color:blue;
} */
.mylogo {
  position: relative;
  left: 1%;
}
@media screen and (max-width: 789px) {
  .mylogo {
    /* display: block;
    margin-left: auto;
    margin-right: auto; */
    width: 35%;
    margin-bottom: 3%;
    margin-top: 2%;
    position: relative;
    right: 35%;
    top: 0%;
    left: unset;
  }
}
/* Please ❤ this if you like it! */

@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900");

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.7;
  color: #c4c3ca;
  background-color: #1f2029;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/pat-back.svg");
  background-position: center;
  background-repeat: repeat;
  background-size: 4%;
  overflow-x: hidden;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
a {
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}

/* #Cursor
================================================== */

.cursor,
.cursor2,
.cursor3 {
  position: fixed;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
  left: -100px;
  top: 50%;
  mix-blend-mode: difference;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
.cursor {
  background-color: #fff;
  height: 0;
  width: 0;
  z-index: 99999;
}
.cursor2,
.cursor3 {
  height: 36px;
  width: 36px;
  z-index: 99998;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.cursor2.hover,
.cursor3.hover {
  -webkit-transform: scale(2) translateX(-25%) translateY(-25%);
  transform: scale(2) translateX(-25%) translateY(-25%);
  border: none;
}
.cursor2 {
  border: 2px solid #fff;
  box-shadow: 0 0 22px rgba(255, 255, 255, 0.6);
}
.cursor2.hover {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 12px rgba(255, 255, 255, 0.2);
}

/* @media screen and (max-width: 1200px) {
  .cursor,
  .cursor2,
  .cursor3 {
    display: none;
  }
} */

/* #Primary style
================================================== */

.section {
  position: relative;
  width: 100%;
  display: block;
}
.over-hide {
  overflow: hidden;
}
.full-height {
  height: 100vh;
}

/* #Navigation
================================================== */

.cd-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}
.header-wrapper {
  position: relative;
  width: calc(100% - 100px);
  margin-left: 50px;
}
.logo-wrap {
  position: absolute;
  display: block;
  right: 0;
  top: 40px;
  cursor: pointer;
}
.logo-wrap a {
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  transition: all 0.3s ease-out;
}
.logo-wrap a span {
  color: #8167a9;
}
.logo-wrap a:hover {
  opacity: 0.9;
}
.nav-but-wrap {
  position: relative;
  display: inline-block;
  float: left;
  padding-left: 15px;
  padding-top: 2%;
  transition: all 0.3s ease-out;
}
.menu-icon {
  height: 30px;
  width: 30px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  display: block;
}
.menu-icon__line {
  height: 2px;
  width: 30px;
  display: block;
  background-color: #fff;
  margin-bottom: 7px;
  cursor: pointer;
  -webkit-transition: background-color 0.5s ease, -webkit-transform 0.2s ease;
  transition: background-color 0.5s ease, -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, background-color 0.5s ease;
  transition: transform 0.2s ease, background-color 0.5s ease,
    -webkit-transform 0.2s ease;
}
.menu-icon__line-left {
  width: 16.5px;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.menu-icon__line-right {
  width: 16.5px;
  float: right;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
}
.menu-icon:hover .menu-icon__line-left,
.menu-icon:hover .menu-icon__line-right {
  width: 30px;
}
.nav {
  position: fixed;
  z-index: 98;
}
.nav:before,
.nav:after {
  content: "";
  position: fixed;
  top: 71px;
  left: 50px;
  width: 0;
  height: 0;
  /*Hello*/
  background-color: rgba(235, 235, 235, 0.4);
  border-bottom-right-radius: 200%;
  z-index: -1;
  transition: border-radius linear 0.8s,
    width cubic-bezier(0.77, 0, 0.175, 1) 0.6s,
    height cubic-bezier(0.77, 0, 0.175, 1) 0.6s;
}
.nav:after {
  background-color: rgb(246, 239, 249);
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/pat.svg");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 300%;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  box-shadow: 6px 6px 22px rgba(218, 216, 221, 0.1);
}
.nav:before {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.nav__content {
  position: fixed;
  visibility: hidden;
  top: 100px;
  left: 50px;
  width: 280px;
  text-align: left;
}
.nav__list {
  position: relative;
  padding: 0;
  margin: 0;
  z-index: 2;
}
.nav__list-item {
  position: relative;
  display: block;
  -webkit-transition-delay: 0.8s;
  transition-delay: 0.8s;
  opacity: 0;
  text-align: left;
  color: #fff;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  line-height: 1.2;
  letter-spacing: 2px;
  -webkit-transform: translate(30px, 0%);
  transform: translate(30px, 0%);
  -webkit-transition: opacity 0.2s ease, -webkit-transform 0.3s ease;
  transition: opacity 0.2s ease, -webkit-transform 0.2s ease;
  transition: opacity 0.2s ease, transform 0.2s ease;
  transition: opacity 0.2s ease, transform 0.2s ease,
    -webkit-transform 0.2s ease;
  margin-top: 7px;
  margin-bottom: 7px;
}
.nav__list-item a {
  position: relative;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  z-index: 2;
  padding-left: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: inline-block;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.nav__list-item a:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 5px;
  height: 0;
  opacity: 0;
  background-color: #8167a9;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.nav__list-item a:hover:after {
  height: 100%;
  opacity: 1;
  top: 0;
}
.nav__list-item a:hover {
  color: #1f2029;
}
.nav__list-item.active-nav a {
  color: #1f2029;
}
.nav__list-item.active-nav a:after {
  height: 100%;
  opacity: 1;
  top: 0;
}
body.nav-active .nav__content {
  visibility: visible;
}
body.nav-active .menu-icon__line {
  background-color: #fff;
  -webkit-transform: translate(0px, 0px) rotate(-45deg);
  transform: translate(0px, 0px) rotate(-45deg);
}
body.nav-active .menu-icon__line-left {
  width: 15px;
  -webkit-transform: translate(2px, 4px) rotate(45deg);
  transform: translate(2px, 4px) rotate(45deg);
}
body.nav-active .menu-icon__line-right {
  width: 15px;
  float: right;
  -webkit-transform: translate(-3px, -3.5px) rotate(45deg);
  transform: translate(-3px, -3.5px) rotate(45deg);
}
body.nav-active .menu-icon:hover .menu-icon__line-left,
body.nav-active .menu-icon:hover .menu-icon__line-right {
  width: 15px;
}
body.nav-active .nav {
  visibility: visible;
}
body.nav-active .nav:before,
body.nav-active .nav:after {
  width: 250px;
  height: 155px;
  /* height: 300px; */
  border-radius: 15px;
}
body.nav-active .nav:after {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
body.nav-active .nav:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
body.nav-active .nav__list-item {
  opacity: 1;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-transition: opacity 0.3s ease, color 0.3s ease,
    -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease,
    -webkit-transform 0.3s ease;
}
body.nav-active .nav__list-item:nth-child(0) {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}
body.nav-active .nav__list-item:nth-child(1) {
  -webkit-transition-delay: 0.8s;
  transition-delay: 0.8s;
}
body.nav-active .nav__list-item:nth-child(2) {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}
body.nav-active .nav__list-item:nth-child(3) {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
}
body.nav-active .nav__list-item:nth-child(4) {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}
body.nav-active .nav__list-item:nth-child(5) {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}
body.nav-active .nav__list-item:nth-child(6) {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}
body.nav-active .nav__list-item:nth-child(7) {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}
body.nav-active .nav__list-item:nth-child(8) {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}
body.nav-active .nav__list-item:nth-child(9) {
  -webkit-transition-delay: 1.6s;
  transition-delay: 1.6s;
}
body.nav-active .nav__list-item:nth-child(10) {
  -webkit-transition-delay: 1.7s;
  transition-delay: 1.7s;
}

.switch-wrap {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 10;
  transform: translateY(-50%);
  width: 100%;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  margin: 0 auto;
  text-align: center;
}
.switch-wrap h1 {
  font-weight: 900;
  font-size: 46px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 40px;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
@media screen and (max-width: 580px) {
  .switch-wrap h1 {
    font-size: 32px;
  }
}
.switch-wrap p {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1;
  color: #8167a9;
  text-align: center;
  margin-top: 15px;
}
.switch-wrap p span {
  position: relative;
}
.switch-wrap p span:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background-color: #fff;
  left: 0;
  bottom: -4px;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
.switch-wrap p span:nth-child(2):before {
  opacity: 0;
}
#switch,
#circle {
  cursor: pointer;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
#switch {
  width: 60px;
  height: 8px;
  bottom: 5%;
  right: 1%;
  margin: 0 auto;
  text-align: center;
  border: 2px solid #000;
  border-radius: 27px;
  background: #8167a9;
  position: fixed;
  display: inline-block;
  z-index: 6;
}
#circle {
  position: absolute;
  top: -11px;
  left: 43px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: #fff;
}
.switched {
  border-color: #8167a9 !important;
  background: #000000 !important;
}
.switched #circle {
  left: -13px;
  background: #000000;
  border-color: #ffffff;
  border: 1px solid #ffffff;
}

/* #Light
================================================== */

body.light {
  background-color: #fff;
  color: #000;
}
/* body.light .cursor,
body.light .cursor2,
body.light .cursor3 {
  mix-blend-mode: normal;
}
body.light .cursor2 {
  border: 2px solid #1f2029;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}
body.light .cursor2.hover {
  background: rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  border-color: transparent;
} */
body.light .cursor2 {
  border: 1px solid #ffffff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}
body.light .cursor2:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    255,
    255,
    255,
    0.5
  ); /* Adjust the color and opacity as needed */
  mix-blend-mode: difference;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  z-index: -1;
}
body.light .logo-wrap a {
  color: #1f2029;
}
body.light .menu-icon__line {
  background-color: #ffffff;
}
body.light .nav:before {
  background-color: rgba(235, 235, 235, 0.4);
}
body.light .nav:after {
  background-color: rgb(246, 239, 249);
  box-shadow: 6px 6px 22px rgba(42, 31, 63, 0.1);
}
body.light .nav__list-item a {
  color: rgba(255, 255, 255, 0.6);
}
body.light .nav__list-item a:hover {
  color: rgba(255, 255, 255, 1);
}
body.light .nav__list-item.active-nav a {
  color: rgba(255, 255, 255, 1);
}
body.light .switch-wrap h1 {
  color: #000;
}
body.light .switch-wrap p span:nth-child(2):before {
  opacity: 1;
  background-color: #000;
}
body.light .switch-wrap p span:nth-child(1):before {
  opacity: 0;
}
body.light .nav:before {
  background-color: rgba(20, 21, 26, 0.6);
}
body.light .nav:after {
  background-color: rgba(9, 9, 12, 1);
}
body.light .navbar-mainbg {
  background-color: #1f2029;
}
body.light .hori-selector .right:before,
body.light .hori-selector .left:before {
  background-color: #1f2029;
}
body.light .hori-selector,
body.light .hori-selector .left,
body.light .hori-selector .right {
  background-color: #ffffff;
}
/* add transition to horiselector to blend background
-webkit-transition: all 300ms linear;
  transition: all 300ms linear;
*/

body.light #navbarSupportedContent > ul > li.active > a {
  color: #5161ce;
}

/* #Link to page
================================================== */
.link-to-portfolio {
  position: fixed;
  bottom: 40px;
  right: 50px;
  z-index: 200;
  cursor: pointer;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 3px;
  background-position: center center;
  background-size: 65%;
  background-repeat: no-repeat;
  background-image: url("https://assets.codepen.io/1462889/fcy.png");
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
  transition: opacity 0.2s, border-radius 0.2s, box-shadow 0.2s;
  transition-timing-function: ease-out;
}
.link-to-portfolio:hover {
  opacity: 0.8;
  border-radius: 50%;
  box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1);
}
.cont {
  position: relative;
  height: 100vh;
  overflow: hidden;
  margin-top: 3%;
  width: 90%;
  margin-left: 5%;
  margin-bottom: 5%;
}

.slider {
  position: relative;
  height: 100%;
  transform: translate3d(0, 0, 0);
  will-change: transform;
  cursor: all-scroll;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.slider.animation {
  transition: transform 750ms ease-in-out;
}
.slider.animation .slide__darkbg {
  transition: transform 750ms ease-in-out;
}
.slider.animation .slide__text {
  transition: transform 750ms ease-in-out;
}
.slider.animation .slide__letter {
  transition: transform 750ms ease-in-out;
}

.slide {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.slide--1 {
  left: 0%;
}
.slide--2 {
  left: 100%;
}
.slide--3 {
  left: 200%;
}
.slide--4 {
  left: 300%;
}
.slide--5 {
  left: 400%;
}
.slide__darkbg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  will-change: transform;
  z-index: 10;
}
.slide__text-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 15;
}
.slide__letter {
  position: absolute;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
  -webkit-text-fill-color: transparent !important;
  -webkit-background-clip: text !important;
  font-size: 19vw;
  font-weight: 800;
  color: #000;
  z-index: 2;
  will-change: transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.slide__letter span{
  -webkit-text-stroke: 3px black
}

.slide__text {
  font-size: 5vw;
  font-weight: 800;
  text-transform: uppercase;
  transform: translate3d(0, 0, 0);
  /* letter-spacing: 12px; */
  color: #fff;
  will-change: transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 28%;
  margin-left: 27%;
}
.slide__text:nth-child(odd) {
  z-index: 2;
}
.slide__text:nth-child(even) {
  z-index: 1;
}
.slide--1__darkbg {
  left: 0%;
  background: url("../public/assets/images/maxresdefault.jpg")
    center center no-repeat;
  background-size: cover;
  background-position: 0px center, 0px center;
  transform: translate3d(0, 0, 0);
  will-change: transform;
}
.slide--1__darkbg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(11, 15, 39, 0.83);
}
.slide--1__letter {
  background: url("../public/assets/images/maxresdefault.jpg")
    center center no-repeat;
  background-position: 0px center, 0px center;
  background-size: cover;
}
.slide--2__darkbg {
  left: -50%;
  background: url("../public/assets/images/914f088b-ac1f-4322-a1ba-7baaa7b0813c-cover.png")
    center center no-repeat;
  background-size: cover;
  background-position: 0px center, 0px center;
  transform: translate3d(0, 0, 0);
  will-change: transform;
}
.slide--2__darkbg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(11, 15, 39, 0.83);
}
.slide--2__letter {
  background: url("../public/assets/images/914f088b-ac1f-4322-a1ba-7baaa7b0813c-cover.png")
    center center no-repeat;
  background-position: 0px center, 0px center;
  background-size: cover;
}
.slide--3__darkbg {
  left: -100%;
  background: url("../public/assets/images/Top-6-Front-End-Web-Development-Tools-to-Increase-Your-Productivity-in-2020-1.jpg")
    center center no-repeat;
  background-size: cover;
  background-position: 0px center, 0px center;
  transform: translate3d(0, 0, 0);
  will-change: transform;
}
.slide--3__darkbg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(11, 15, 39, 0.83);
}
.slide--3__letter {
  background: url("../public/assets/images/Top-6-Front-End-Web-Development-Tools-to-Increase-Your-Productivity-in-2020-1.jpg")
    center center no-repeat;
  background-position: 0px center, 0px center;
  background-size: cover;
}
.slide--4__darkbg {
  left: -150%;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/city--4-min-min.jpg")
    center center no-repeat;
  background-size: cover;
  background-position: 0px center, 0px center;
  transform: translate3d(0, 0, 0);
  will-change: transform;
}
.slide--4__darkbg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(11, 15, 39, 0.83);
}
.slide--4__letter {
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/city--4-min-min.jpg")
    center center no-repeat;
  background-position: 0px center, 0px center;
  background-size: cover;
}
.slide--5__darkbg {
  left: -200%;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/city--5-min-min.jpg")
    center center no-repeat;
  background-size: cover;
  background-position: 0px center, 0px center;
  transform: translate3d(0, 0, 0);
  will-change: transform;
}
.slide--5__darkbg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(11, 15, 39, 0.83);
}
.slide--5__letter {
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/city--5-min-min.jpg")
    center center no-repeat;
  background-position: 0px center, 0px center;
  background-size: cover;
}

.nav2 {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  list-style-type: none;
  z-index: 10;
}
.nav__slide {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin-left: 10px;
  cursor: pointer;
}
@media screen and (max-width: 400px) {
  .nav__slide {
    width: 22px;
    height: 22px;
  }
}
.nav__slide:hover:after {
  transform: translate(-50%, -50%) scale(1, 1);
  opacity: 1;
}
.nav__slide:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0, 0);
  width: 75%;
  height: 75%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0;
  transition: 300ms;
}
.nav__slide--1 {
  margin-left: 0;
}

.nav-active:after {
  transform: translate(-50%, -50%) scale(1, 1);
  opacity: 1;
}

.side-nav {
  position: absolute;
  width: 10%;
  height: 100%;
  top: 0;
  z-index: 5;
  cursor: pointer;
  opacity: 0;
  transition: 300ms;
}
.side-nav:hover {
  opacity: 0.1;
}
.side-nav--right {
  right: 0;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    #eed7ff 100%
  );
}
.side-nav--left {
  left: 0;
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 0%,
    #eed7ff 100%
  );
}

html {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

/* body {
  background-color: #000;
  overflow: hidden;
} */

/* @import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");
* {
  box-sizing: border-box;
}

body {
  background-color: #FFE53B;
  background-image: linear-gradient(147deg, #FFE53B 0%, #fd3838 74%);
  min-height: 100vh;
  font-family: "Fira Sans", sans-serif;
  display: flex;
} */

.blog-slider {
  width: 95%;
  position: relative;
  max-width: 800px;
  margin: auto;
  background: #fff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 25px;
  border-radius: 25px;
  height: 400px;
  transition: all 0.3s;
  margin-bottom: 5%;
}
@media screen and (max-width: 992px) {
  .blog-slider {
    max-width: 680px;
    height: 400px;
  }
}
@media screen and (max-width: 852px) {
  .blog-slider {
    min-height: 500px;
    height: auto;
    margin: 180px auto;
  }
  .card{
    height: 400px;
    width: 100% !important;
  }
  .content{
    height: auto !important;
  }
}
@media screen and (max-height: 500px) and (min-width: 992px) {
  .blog-slider {
    height: 350px;
  }
}
.blog-slider__item {
  display: flex;
  align-items: center;
  /* background-image: url('./consulting-crop.jpg'); */
}
@media screen and (max-width: 852px) {
  .blog-slider__item {
    flex-direction: column;
  }
}
.blog-slider__item.swiper-slide-active .blog-slider__img img {
  opacity: 1;
  transition-delay: 0.3s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > * {
  opacity: 1;
  transform: none;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(1) {
  transition-delay: 0.3s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(2) {
  transition-delay: 0.4s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(3) {
  transition-delay: 0.5s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(4) {
  transition-delay: 0.6s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(5) {
  transition-delay: 0.7s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(6) {
  transition-delay: 0.8s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(7) {
  transition-delay: 0.9s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(8) {
  transition-delay: 1s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(9) {
  transition-delay: 1.1s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(10) {
  transition-delay: 1.2s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(11) {
  transition-delay: 1.3s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(12) {
  transition-delay: 1.4s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(13) {
  transition-delay: 1.5s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(14) {
  transition-delay: 1.6s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(15) {
  transition-delay: 1.7s;
}
.blog-slider__img {
  width: 300px;
  flex-shrink: 0;
  height: 300px;
  background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
  box-shadow: 4px 13px 30px 1px rgba(252, 56, 56, 0.2);
  border-radius: 20px;
  transform: translateX(-80px);
  overflow: hidden;
  /* background-image: url('./consulting-crop.jpg'); */
}
.blog-slider__img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
  border-radius: 20px;
  opacity: 0.8;
}
.blog-slider__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  opacity: 0;
  border-radius: 20px;
  transition: all 0.3s;
}
@media screen and (max-width: 852px) {
  .blog-slider__img {
    transform: translateY(-50%);
    width: 90%;
  }
}
@media screen and (max-width: 576px) {
  .blog-slider__img {
    width: 95%;
  }
}
@media screen and (max-height: 500px) and (min-width: 992px) {
  .blog-slider__img {
    height: 270px;
  }
}
.blog-slider__content {
  padding-right: 25px;
}
@media screen and (max-width: 852px) {
  .blog-slider__content {
    margin-top: -80px;
    text-align: center;
    padding: 0 30px;
  }
}
@media screen and (max-width: 576px) {
  .blog-slider__content {
    padding: 0;
  }
}
.blog-slider__content > * {
  opacity: 0;
  transform: translateY(25px);
  transition: all 0.4s;
}
.blog-slider__code {
  color: #7b7992;
  margin-bottom: 15px;
  display: block;
  font-weight: 500;
}
.blog-slider__title {
  font-size: 24px;
  font-weight: 700;
  color: #0d0925;
  margin-bottom: 20px;
}
.blog-slider__text {
  color: #4e4a67;
  margin-bottom: 30px;
  line-height: 1.5em;
}
.blog-slider__button {
  display: inline-flex;
  background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
  padding: 15px 35px;
  border-radius: 50px;
  color: #fff;
  box-shadow: 0px 14px 80px rgba(252, 56, 56, 0.4);
  text-decoration: none;
  font-weight: 500;
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
}
/* @media screen and (max-width: 576px) {
  .blog-slider__button {
    width: 100%;
  }
} */
.blog-slider .swiper-container-horizontal > .swiper-pagination-bullets,
.blog-slider .swiper-pagination-custom,
.blog-slider .swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}
.blog-slider__pagination {
  position: absolute;
  z-index: 21;
  right: 20px;
  width: 11px !important;
  text-align: center;
  left: auto !important;
  top: 50%;
  bottom: auto !important;
  transform: translateY(-50%);
}
@media screen and (max-width: 852px) {
  .blog-slider__pagination {
    transform: translateX(-50%);
    left: 50% !important;
    top: 205px;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.blog-slider__pagination.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 8px 0;
}
@media screen and (max-width: 852px) {
  .blog-slider__pagination.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px;
  }
}
.blog-slider__pagination .swiper-pagination-bullet {
  width: 11px;
  height: 11px;
  display: block;
  border-radius: 10px;
  background: #062744;
  opacity: 0.2;
  transition: all 0.3s;
}
.blog-slider__pagination .swiper-pagination-bullet-active {
  opacity: 1;
  background: #fd3838;
  height: 30px;
  box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.3);
}
@media screen and (max-width: 852px) {
  .blog-slider__pagination .swiper-pagination-bullet-active {
    height: 11px;
    width: 30px;
  }
}




.content {
  display: flex;
  margin: 0 auto;
  margin-bottom: 5%;
  justify-content: center;
  /* align-items: center; */
  flex-wrap: wrap;
  /* max-width: 1000px; */
  box-sizing: border-box;
  height: 434px;
}

/* .heading {
  width: 100%;
  margin-left: 1rem;
  font-weight: 900;
  font-size: 1.618rem;
  text-transform: uppercase;
  letter-spacing: 0.1ch;
  line-height: 1;
  padding-bottom: 0.5em;
  margin-bottom: 1rem;
  position: relative;
}
.heading:after {
  display: block;
  content: "";
  position: absolute;
  width: 60px;
  height: 4px;
  background: linear-gradient(135deg, #1a9be6, #1a57e6);
  bottom: 0;
  box-sizing: border-box;
}

.description {
  width: 100%;
  margin-top: 0;
  margin-left: 1rem;
  margin-bottom: 3rem;
} */

.card {
  color: inherit;
  cursor: pointer;
  width: calc(48% - 2rem);
  min-width: calc(48% - 2rem);
  perspective: 1000px;
  margin: 1rem;
  position: relative;
  box-sizing: border-box;
  border: none;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/pat-back.svg");
  background-position: center;
  background-repeat: repeat;
  background-size: 12%;
  background-color: #1f2029;
}

body.light .card{
 background-color: #ffffff;
}

/* @media screen and (max-width: 800px) {
  .card {
    width: calc(50% - 2rem);
  }
}
@media screen and (max-width: 500px) {
  .card {
    width: 100%;
  }
} */

.front,
.back {
  display: flex;
  border-radius: 6px;
  background-position: center;
  background-size: cover;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: ease-in-out 600ms;
  box-sizing: border-box;
  height:400px;
}

.front {
  background-size: cover;
  padding: 2rem;
  font-size: 1.618rem;
  font-weight: 600;
  color: #fff;
  overflow: hidden;
  font-family: Poppins, sans-serif;
}

#bl {
  background-image: url("../public/assets/images/business_laws.jpg");
}
#cs {
  background-image: url("../public/assets/images/clients_server.jpg");
}

.front:before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #1a9be6, #1a57e6);
  opacity: 0.25;
  z-index: -1;
  box-sizing: border-box;
}

.back:before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #1a9be6, #1a57e6);
  opacity: 0.25;
  z-index: -1;
  box-sizing: border-box;
}

.card:hover .front {
  transform: rotateY(180deg);
}
.card:nth-child(even):hover .front {
  transform: rotateY(-180deg);
}

.back {
  /* background: red; */
  transform: rotateY(-180deg);
  font-size: 1.618rem;
  font-weight: 600;
  color: #fff;
  padding: 0 2em;
}
.back .cardbutton {
  background: linear-gradient(135deg, #1a9be6, #1a57e6);
  font-family: Poppins, sans-serif;
}
.back .cardbutton:before {
  box-shadow: 0 0 10px 10px rgba(26, 87, 230, 0.25);
  background-color: rgba(26, 87, 230, 0.25);
  box-sizing: border-box;
}
.card:hover .back {
  transform: rotateY(0deg);
}
.card:nth-child(even) .back {
  transform: rotateY(180deg);
}
.card:nth-child(even) .back .cardbutton {
  background: linear-gradient(135deg, #e61a80, #e61a3c);
}
.card:nth-child(even) .back .cardbutton:before {
  box-shadow: 0 0 10px 10px rgba(230, 26, 60, 0.25);
  background-color: rgba(230, 26, 60, 0.25);
  box-sizing: border-box;
}
.card:nth-child(even):hover .back {
  transform: rotateY(0deg);
}

.cardbutton {
  transform: translateZ(40px);
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-weight: bold;
  color: #fff;
  padding: 0.5em 1em;
  border-radius: 100px;
  font: inherit;
  border: none;
  position: relative;
  transform-style: preserve-3d;
  transition: 300ms ease;
}
.cardbutton:before {
  transition: 300ms ease;
  position: absolute;
  display: block;
  content: "";
  transform: translateZ(-40px);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  border-radius: 100px;
  left: 10px;
  top: 16px;
  box-sizing: border-box;
}
.cardbutton:hover {
  transform: translateZ(55px);
}
.cardbutton:hover:before {
  transform: translateZ(-55px);
  box-sizing: border-box;
}
.cardbutton:active {
  transform: translateZ(20px);
}
.cardbutton:active:before {
  transform: translateZ(-20px);
  top: 12px;
  box-sizing: border-box;
}