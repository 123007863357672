// @import url(https://pro.fontawesome.com/releases/v5.11.2/css/all.css);

:root {
  --user-button-circle: rgba(0, 0, 0, 0.025);
  --user-button-cardborder: rgba(255, 255, 255, 0.25);
  --user-button-text: #323133;
  --user-button-shadow: rgba(0, 0, 0, 0.1);
}

body #user-button {
  z-index: 1000;
  bottom: 1rem !important;
  right: 1rem !important;
  color: var(--user-button-text);
  transition: 1s 0s ease-out;
  -webkit-animation: slide 3s ease-out forwards;
  animation: slide 3s ease-out forwards;
}

@-webkit-keyframes slide {

  0%,
  50% {
    opacity: 0;
    display: block !important;
  }

  100% {
    opacity: 1;
    display: block !important;
  }
}

@keyframes slide {

  0%,
  50% {
    opacity: 0;
    display: block !important;
  }

  100% {
    opacity: 1;
    display: block !important;
  }
}

body #user-button .u-card {
  border-radius: 100%;
  box-shadow: 0 0 1rem -0.25rem var(--user-button-shadow), inset 0 0 1rem -0.75rem var(--user-button-shadow);
}

body #user-button .u-main {
  cursor: pointer;
  --user-button-background: var(--user-button-main, #EC87C0);
}

body #user-button .u-main img {
  height: 100%;
  width: 100%;
}

body #user-button .u-main iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 4rem;
  height: 4rem;
  opacity: 1;
  transition: 0s 4s;
}

body #user-button .u-icons {
  position: relative;
  z-index: 950;
  transform: translate(-50%, -50%);
  background: var(--user-button-circle);
  box-shadow: 0 0 0 0.125rem var(--user-button-cardborder);
  border-radius: 100%;
  transition: 0.25s;
  opacity: 1 !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  /*&:before {
     z-index:-1;
     position:absolute;
     top:0; right:0; bottom:0; left:0;
     content:'';

     backdrop-filter: blur(10px);
  }*/
}

body #user-button .u-icons a {
  color: inherit;
  display: grid;
  place-items: center;
  width: 30px;
  height: 30px;
  text-decoration: none;
}

body #user-button .u-icons a div {
  padding: 0.5rem;
  transition: 0s;
}

body #user-button .u-icons a[href="https://twitter.com/zed_dash"] {
  position: relative;
}

body #user-button .u-icons a[href="https://twitter.com/zed_dash"]:before {
  content: "Middle Click";
  position: absolute;
  top: -1.5rem;
  left: 50%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  white-space: pre;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: 0.25s ease-in;
  background: #fffc;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

body #user-button .u-icons a[href="https://twitter.com/zed_dash"].show:before {
  opacity: 1;
  transition: 0.25s ease-out;
}

body #user-button .u-icons a[href="https://twitter.com/zed_dash"] div {
  color: #1da1f2;
}

body #user-button .u-icons a[href="https://codepen.io/z-"] div {
  background: black;
  color: white;
}

body #user-button .u-icons a.u-random div {
  position: relative;
  top: -1px;
  -webkit-animation: diespin 2s linear infinite;
  animation: diespin 2s linear infinite;
}

@-webkit-keyframes diespin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes diespin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

body #user-button .u-icons a.u-random:not(:hover) div {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

body #user-button .u-icons>* {
  position: absolute;
  width: 30px;
  height: 30px;
  background: var(--singlecolour);
  border-radius: 100%;
  cursor: pointer;
  transform: translate(-50%, -50%);
  transition: 0.25s -0.05s;
}

body #user-button .u-icons>*:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body #user-button .u-icons>*:hover,
body #user-button .u-icons>*:focus-within {
  background: var(--hcolour);
}

body #user-button .u-icons>*:first-child:nth-last-child(1):nth-child(1),
body #user-button .u-icons>*:first-child:nth-last-child(1)~*:nth-child(1) {
  left: 25%;
  top: 25%;
}

body #user-button .u-icons>*:first-child:nth-last-child(2):nth-child(1),
body #user-button .u-icons>*:first-child:nth-last-child(2)~*:nth-child(1) {
  left: 37.5%;
  top: 18.75%;
}

body #user-button .u-icons>*:first-child:nth-last-child(2):nth-child(2),
body #user-button .u-icons>*:first-child:nth-last-child(2)~*:nth-child(2) {
  left: 18.75%;
  top: 37.5%;
}

body #user-button .u-icons>*:first-child:nth-last-child(3):nth-child(1),
body #user-button .u-icons>*:first-child:nth-last-child(3)~*:nth-child(1) {
  left: 50%;
  top: 15.625%;
}

body #user-button .u-icons>*:first-child:nth-last-child(3):nth-child(2),
body #user-button .u-icons>*:first-child:nth-last-child(3)~*:nth-child(2) {
  left: 25%;
  top: 25%;
}

body #user-button .u-icons>*:first-child:nth-last-child(3):nth-child(3),
body #user-button .u-icons>*:first-child:nth-last-child(3)~*:nth-child(3) {
  left: 15.625%;
  top: 50%;
}

body #user-button .u-icons>*:first-child:nth-last-child(4):nth-child(1),
body #user-button .u-icons>*:first-child:nth-last-child(4)~*:nth-child(1) {
  left: 62.5%;
  top: 18.75%;
}

body #user-button .u-icons>*:first-child:nth-last-child(4):nth-child(2),
body #user-button .u-icons>*:first-child:nth-last-child(4)~*:nth-child(2) {
  left: 37.5%;
  top: 18.75%;
}

body #user-button .u-icons>*:first-child:nth-last-child(4):nth-child(3),
body #user-button .u-icons>*:first-child:nth-last-child(4)~*:nth-child(3) {
  left: 18.75%;
  top: 37.5%;
}

body #user-button .u-icons>*:first-child:nth-last-child(4):nth-child(4),
body #user-button .u-icons>*:first-child:nth-last-child(4)~*:nth-child(4) {
  left: 18.75%;
  top: 62.5%;
}

body #user-button:hover .u-icons,
body #user-button:focus-within .u-icons {
  width: 300% !important;
  height: 300% !important;
}

.title-solution{
  margin-bottom: 2%;
  font-family: "Euclid Circular A", sans-serif;;
}

// body {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   overflow: hidden;
//   height: 100vh;
//   font-family: "Roboto", sans-serif;
//   transition: 0.25s;
// }

// body.dark {
//   background: #232223;
//   color: white;
// }

// @media (prefers-color-scheme: dark) {
//   body:not(.light) {
//     background: #232223;
//     color: white;
//   }
// }

body .credit {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: inherit;
}

body .options {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
  overflow: hidden;
  min-width: 600px;
  max-width: 900px;
  width: calc(100% - 100px);
  height: 400px;
  margin-left: 10%;
  margin-bottom: 5%;
  margin-top: 5%;
}

@media screen and (max-width: 992px) {
  body .options {
    margin-left: 5%;
  }
  body .options .option .label .info .main {
    font-size: 1rem !important;
  }
  body .options .option .label .info .sub {
    font-size: 0.6rem !important;
  }
}

@media screen and (max-width: 750px) {
  body .options {
    min-width: 520px;
  }

  body .options .option .label .info .main {
    font-size: 0.8rem !important;
  }
  body .options .option .label .info .sub {
    font-size: 0.5rem !important;
  }
  body .options .option.active .label {
    left: 5px !important;
  }
  body .options .option .label .icon {
    min-width: 30px !important;
    max-width: 30px !important;
    height: 30px !important;
  }
  body .options .option.active .label .icon {
    padding-left: 2.5% !important;
  }
  body .options .option .label .icon {
    padding-left: 15% !important;
  }

  // body .options .option:nth-child(5) {
  //   display: none;
  // }
}

@media screen and (max-width: 500px) {
  body .options {
    flex-direction: column !important;
    flex-wrap: inherit;
  }
}

@media screen and (max-width: 638px) {
  body .options {
    min-width: 440px;
  }
  body .options .option{
    background-size: auto 400% !important;
  }
  body .options .option .label .info {
    font-weight: 900 !important;
  }

  body .options .option {
    flex-basis: calc(40%);
  }

  body .options .option .label .icon{
    padding-left: 2.5% !important;
  }

  // body .options .option:nth-child(4) {
  //   display: none;
  // }
}

@media screen and (max-width: 558px) {
  body .options {
    min-width: 360px;
  }

  // body .options .option:nth-child(3) {
  //   display: none;
  // }
}

@media screen and (max-width: 478px) {
  body .options {
    min-width: 280px;
  }

  // body .options .option:nth-child(2) {
  //   display: none;
  // }
}

body .options .option {
  position: relative;
  overflow: hidden;
  min-width: 60px;
  margin: 1%;
  background: var(--optionBackground, var(--defaultBackground, #E6E9ED));
  background-size: auto 120%;
  background-position: center;
  cursor: pointer;
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  box-shadow: 0px 0px 5px white;
}

body.light .options .option {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
}

body .options .option:nth-child(1) {
  --defaultBackground: #ED5565;
}

body .options .option:nth-child(2) {
  --defaultBackground: #FC6E51;
}

body .options .option:nth-child(3) {
  --defaultBackground: #FFCE54;
}

body .options .option:nth-child(4) {
  --defaultBackground: #2ECC71;
}

body .options .option:nth-child(5) {
  --defaultBackground: #5D9CEC;
}

body .options .option:nth-child(6) {
  --defaultBackground: #AC92EC;
}

body .options .option.active {
  flex-grow: 10000;
  transform: scale(1);
  max-width: 600px;
  margin: 1%;
  border-radius: 40px;
  background-size: auto 100%;
  // &:active {
    //  transform:scale(5);
  // }
  pointer-events: none;
}

body .options .option.active .label .icon {
  padding-left: 1.5%;
}

body .options .option.active .shadow {
  box-shadow: inset 0 -120px 120px -120px black, inset 0 -120px 120px -100px black;
}

body .options .option.active .label {
  bottom: 20px;
  left: 20px;
}

body .options .option.active .label .info>div {
  left: 0px;
  opacity: 1;
  text-align: left;
}

body .options .option:not(.active) {
  flex-grow: 1;
  border-radius: 30px;
}

body .options .option:not(.active) .shadow {
  bottom: -40px;
  box-shadow: inset 0 -120px 0px -120px black, inset 0 -120px 0px -100px black;
}

body .options .option:not(.active) .label {
  bottom: 10px;
  left: 10px;
}

body .options .option:not(.active) .label .info>div {
  left: 20px;
  opacity: 0;
}

body .options .option .shadow {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 120px;
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
}

body .options .option .label {
  display: flex;
  position: absolute;
  right: 0px;
  height: 40px;
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
}

body .options .option .label .icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: white;
  color: var(--defaultBackground);
  padding-left: 6%;
}

body .options .option .label .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  color: white;
  white-space: pre;
}

body .options .option .label .info>div {
  position: relative;
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95), opacity 0.5s ease-out;
}

body .options .option .label .info .main {
  font-weight: bold;
  font-size: 1.2rem;
}

body .options .option .label .info .sub {
  transition-delay: 0.1s;
}

.main-more-info {
  color: white;
  text-decoration: underline;
  cursor: pointer;
  pointer-events: all;
  margin-left: 5px;
}

.main-more-info:hover {
  color: #b5abab;
}